import React from 'react'
import './Sobre.css'
import Links from '../../Components/Links/Links'
import Inside from '../../Components/Inside/Inside'
import Navbar from '../../Components/Navbar/Navbar'

const Sobre = () => {
    return (
        <div className='sobre__container'>
            <header>
                <Navbar />
            </header>

            <main>
                <div className="sobre__align--container">
                    <h2 className='sobre__h2'>Aprender e Capacitar</h2>
                    <div className="sobre__info--container">
                        <img src="'../../images/mario.png" className='sobre__img sobre__img--mobile' alt="Foto do Mario Lucas" />
                        <p className='sobre__text'>
                            A instituição “Aprender e Capacitar Brasil” foi fundada no ano de 2022 e tem o intuito de focar no aprendizado e expansão de conhecimento político.
                            O projeto visa abordar pessoas que já estão inseridas na área ou que estão em busca da inserção.
                            Reúne em seus eventos palestrantes que são protagonistas de um vasto currículo e capacitação, onde possuem domínio dos assuntos abordados.
                            A finalidade é agregar valor aos contribuintes que tornam realidade todo o planejamento e idealização realizados pelos membros que constituem o projeto.
                        </p>

                        <div className="sobre__img--container">
                            <img src="'../../images/mario.png" className='sobre__img--desktop' alt="Foto do Mario Lucas" />
                            <blockquote>
                                <p>"A educação é a arma mais poderosa que você pode usar para mudar o mundo."</p>
                                <cite>- Nelson Mandela</cite>
                            </blockquote>
                        </div>
                    </div>

                    <Links />
                </div>
            </main>

            <footer>
                <Inside />
            </footer>
        </div >


    )
}

export default Sobre