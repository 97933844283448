import React from 'react'
import './Eventos.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Events from '../../Components/Events/Events'
import Inside from '../../Components/Inside/Inside'



const Eventos = () => {
    return (
        <div className='eventos'>
            <header>
                <Navbar />
            </header>

            <main>
                <Links />
                <Events titulo='Eventos' />
            </main>

            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default Eventos