import React from 'react'
import './Inside.css'
import { Link } from "react-router-dom";

const Inside = () => {

    const textos = [
        {
            titulo: 'Fique por dentro',
            texto: 'Receba em primeira mão lançamentos e novidades',
            placeHolder: 'Insira o seu e-mail aqui',
            botao: 'Inscreva-se',
            rodape: 'Aprender e Capacitar Brasil'
        }
    ]

    return (
        <section className='inside__container'>
            {textos.map((item, index) => {
                return (
                    <div key={index} className='inside__itens--container'>
                        <h2 className='inside__h2'>{item.titulo}</h2>
                        <div className="inside__textos">
                            <p>{item.texto}</p>

                            <form className="inside__textos--input">
                                <input type="email" className='inside__input' placeholder={item.placeHolder} />
                                <Link className='inside__button'>
                                    {item.botao}
                                </Link>
                            </form>
                        </div>

                        <p className='inside__rodape'>{item.rodape}</p>
                    </div>
                )
            })}
        </section>
    )
}

export default Inside