import React, { useState } from 'react'
import './Navbar.css'

import { Link } from 'react-router-dom';

import { BiMenu } from 'react-icons/bi';
import { FiX } from 'react-icons/fi'

const Navbar = () => {

    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
        setToggle(!toggle);
    }

    return (
        <section className='navbar'>
            <nav className='nav'>
                <ul className={toggle ? 'nav__ul expanded' : 'nav__ul'}>
                    <li><Link className='nav__ul--li' to="/">Home</Link></li>
                    <li><Link className='nav__ul--li' to="/Sobre">Sobre</Link></li>
                    <li><Link className='nav__ul--li' to="/Eventos">Eventos</Link></li>
                    <li><Link className='nav__ul--li' to="/Contato">Contato</Link></li>
                    <li><Link className='nav__ul--li' to="/Galeria">Galeria</Link></li>
                </ul>

                <Link to='/' className='nav__logo--container'>
                    <img src="./images/logo.png" className='nav__logo' alt="Logo da Aprender e Capacitar" />
                </Link>

                <Link to='https://hml.aprenderecapacitarbrasil.com.br/portal' className='nav__p' target='_blank'>
                    <span>Acessar Portal</span>
                </Link>

                <div className="nav__icons--container" onClick={handleToggle}>
                    {toggle ? <FiX size={28} className='nav__icon--x' /> : <BiMenu size={32} className='nav__icon--hamb' />}
                </div>
            </nav>
        </section>
    )
}

export default Navbar