import React from 'react'
import './Home.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Slider from '../../Components/Slider/Slider'
import Events from '../../Components/Events/Events'
import Inside from '../../Components/Inside/Inside'

const Home = () => {
    return (
        <div className='home__container'>
            <header>
                <Navbar />
            </header>

            <main className='home__main'>
                <Slider />
                <Links />
                <Events />
            </main>

            <footer className='home__footer'>
                <Inside />
            </footer>
        </div>
    )
}

export default Home