import React, { useEffect } from 'react'
import './PrimeiroEvento.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Inside from '../../Components/Inside/Inside'


const PrimeiroEvento = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const dataLocal = 'Qui. 25 a 28 de mai | Hotel Littoral'
    const nomeEvento = 'Congresso Nacional de Agentes Públicos e Políticos'

    const horario = 'De 25 a 28 de Maio'
    const local = 'Hotel Littoral, Cabo Branco - PB'

    return (
        <div className='eventos__container'>
            <header>
                <Navbar />
            </header>

            <main>
                <div className="eventos__align--container">
                    <div className="eventos__text--container">
                        <p>{dataLocal}</p>
                        <h1>{nomeEvento}</h1>
                    </div>

                    <div className="eventos__img--container">
                        <img src="./images/primeiroEvento - desktop.png" className='eventos__img--desktop' alt="Congresso Nordestino de Agentes Públicos e Políticos" />
                        <img src="./images/primeiroEvento - mobile.png" className='eventos__img--tablet' alt="Congresso Nordestino de Agentes Públicos e Políticos" />
                    </div>

                    <section className='eventos__section'>
                        <section className='eventos__section--local'>
                            <h2>Local e Horário</h2>
                            <p>{horario}</p>
                            <p>{local}</p>
                        </section>

                        <section className='eventos__section--about'>
                            <h2>Sobre o evento</h2>

                            <div className="section__about--container">
                                <section>
                                    <h3>25 de maio - Quinta-feira</h3>
                                    <p>15:00h - Abertura oficial com o cântico do hino nacional pelo tenor Igor Alves, e formação de mesa.</p>
                                    <p>15:15h - Abertura oficial pela presidenta da UVB-PB, a vereadora Valeska Magalhães, e o presidente da UVP-PE Leo do ar.</p>
                                    <p>15:30h - O ouvidor do estado da Paraíba André Motta representante do governo do estado.</p>
                                    <p>15:45h - O presidente da OAB-PB Dr. Harrison Targino.</p>
                                    <p>16:00h - O presidente da Assembléia Legislativa-PB Deputado Adriano Galdino.</p>
                                    <p>16:30h - Palestra da advogada Dra. Gabriela Rolemberg, especialista em direito eleitoral e representante do DOME. Tema: "Por que quero mulheres eleitas?"</p>
                                    <p>17:50h - Entrega de certificado dos anfitriões do estado da Paraíba, e os palestrantes convidados com fotografia oficial da abertura.</p>
                                </section>

                                <section>
                                    <h3>26 de maio - Sexta-feira</h3>
                                    <p>09:00h - Palestra pela Senadora Teresa Leitão e pelo Deputado Federal Carlos Veras. Tema: Reflexo da política do governo nacional nos municípios.</p>
                                    <p>10:00h - Palestrante Márcia Conrado, presidente da AMUPE e Prefeita de Serra Talhada.</p>
                                    <p>11:00h - O advogado João Batista. Tema: Controle externo exercido pela câmara municipal.</p>
                                    <p>14:30h - A advogada Dra. Amanda Cunha, especialista em direito eleitoral. Tema: Sobra eleitoral e coeficiente.</p>
                                    <p>15:30h - O advogado Dr. Antônio Ribeiro Júnior, especialista em direito eleitoral. Tema: Fake news.</p>
                                    <p>16:30h - A advogada Dra. Thiciane Carneiro, presidente da comissão de direito eleitoral. Tema: Diferenças controvertidas dos TRE'S e TSE e seus julgados.</p>
                                </section>

                                <section>
                                    <h3>27 de maio - Sábado</h3>
                                    <p>09:00h - Apresentação pela DOME. Tema: Quero você eleita. Representante: Amanda Barbosa.</p>
                                    <p>09:40h - Palestra com Aldemar Silva dos Santos "DEMA", secretário de governoprefeitura de Recife e auditor TCE-PE. Tema: Inter-relação entre os poderes legislativo e executivo.</p>
                                    <p>10:30h - Advogado presidente da OAB-PB, especialista em direito administrativo e eleitoral, Dr. Harrison Targino e o advogado Dr. André Motta, juntos no mesmo painel. Tema: Condutas vedadas no poder público, durante o período eleitoral.</p>
                                </section>

                                <section>
                                    <h3>28 de maio - Domingo</h3>
                                    <p>09:00h - Coach e professor, Rodrigo Apolinário.</p>
                                    <p>10:00h - Professor e delegado da polícia civil-PE, delegacia de captura-DRACOM, Dr. Paulo Furtado. Tema: "A atuação dos agentes públicos à luz do direito penal".</p>
                                    <p>11:30h - Fotografia oficial com entrega dos certificados e encerramento do Congresso Nacional.</p>
                                </section>
                            </div>
                        </section>
                    </section>

                    <Links />
                </div>
            </main>

            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default PrimeiroEvento