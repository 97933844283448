import React, { useEffect } from 'react'
import './SegundoEvento.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Inside from '../../Components/Inside/Inside'


const SegundoEvento = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const dataLocal = 'Qui. 24 a 26 de ago | Hotel Garden'
    const nomeEvento = 'III Seminário Nordestino de Agentes Públicos e Políticos'

    const horario = 'De 24 a 26 de Agosto'
    const local = 'Hotel Garden, Campina Grande - PB'

    return (
        <div className='eventos__container'>
            <header>
                <Navbar />
            </header>

            <main>
                <div className="eventos__align--container">
                    <div className="eventos__text--container">
                        <p>{dataLocal}</p>
                        <h1>{nomeEvento}</h1>
                    </div>

                    <div className="eventos__img--container">
                        <img src="./images/segundoEvento - desktop.png" className='eventos__img--desktop' alt="II Seminário Nordestino de Agentes Públicos e Políticos" />
                        <img src="./images/segundoEvento - mobile.png" className='eventos__img--tablet' alt="II Seminário Nordestino de Agentes Públicos e Políticos" />
                    </div>

                    <section className='eventos__section'>
                        <section className='eventos__section--local'>
                            <h2>Local e Horário</h2>
                            <p>{horario}</p>
                            <p>{local}</p>
                        </section>

                        <section className='eventos__section--about'>
                            <h2>Sobre o evento</h2>

                            <div className="section__about--container">
                                <section>
                                    <h3>24 de Agosto - Quinta Feira</h3>
                                    <p>15:00h - Abertura com:</p>
                                    <p>- Presidente do instituto A&C BR Mario Lucas;</p>
                                    <p>- Presidente da câmara de Campina Grande, Marinaldo Cardoso;</p>
                                    <p>- Presidente da UVP, Léo do Ar;</p>
                                    <p>- Deputado Estadual da Paraíba, Tovar Correia;</p>
                                    <p>- Presidenta da UVB-PB, Valeska Magalhães</p>
                                    <p>16:00h - Vereadora Jô Oliveira, com o tema: A importância da mulher na política.</p>
                                </section>

                                <section>
                                    <h3>25 de Agosto - Sexta Feira</h3>
                                    <p>08:30h - Abertura com Juíza de Campina Grande, Dra Ana Cristina, com o tema: O papel do Judiciário Eleitoral no controle da legitimidade eleitoral.</p>
                                    <p>09:30h - Deputado Estadual-PE, Alberto Feitosa, com tema: Emendas impositivas e como implantar nas cidades.</p>
                                    <p>10:50h - Defensor Público-PE, José Wilker, com o tema: Atuação da Defensoria Pública no fomento de políticas públicas voltadas à população em situação de vulnerabilidade.</p>
                                    <p>11:40h - Desembargador do TJ-PE, Dr Bartolomeu Bueno, com o tema: Tributo e receitas próprias de municípios.</p>
                                    <p>14:30h - Presidente da Caap-PE, Anne Cabral, com o tema: Propaganda eleitoral antecipada, o pode e não pode para eleição de 2024.</p>
                                    <p>15:30h - Ouvidor da Paraiba, Andre Motta, com o tema: Democracia conectada e Eleição.</p>
                                    <p>16:30h - Advogado Municipalista e Eleitoralista, Dr. Delmiro Campos, com tema: Desafios legais que permeiam as atividades eleitorais e o sistema político contemporâneo.</p>
                                    <p>17:30h - Acacia Suassuna, Procuradora TRE-PB, com o tema: Fraude a cota de gênero.</p>
                                </section>

                                <section>
                                    <h3>26 de Agosto - Sábado</h3>
                                    <p>08:30h - Painel com os jornalistas: Edmar Lyra, Elizabeth Falcão e Júnior Campos.</p>
                                    <p>09:30h - Advogada especialista em direito médico, Mariane Moraes, com o tema: Direito médico.</p>
                                    <p>10:30h - Conselheiro do TCE-PE, Rodrigo Novaes, com o tema: O papel do TCE junto às câmaras municipais de vereadores.</p>
                                    <p>11:30h - João Suassuna ex diretor geral da Adepe, com o tema: Na Trilha do Mestre Ariano Suassuna.</p>
                                </section>

                                <section>
                                    <h3>27 de Agosto - Domingo</h3>
                                    <p>08:30h - Advogado Municipalista Conselheiro-OAB/PE, Marcus Alencar, com o tema: Julgamento de prestação de contas pelo poder legislativo.</p>
                                    <p>09:30h - Ex-secretário Executivo de Justiça, Waldemar Borges, com o tema: O censo 2022 e o impacto nas Câmaras Legislativas Municipais.</p>
                                    <p>10:30h - Procurador-PE, Rafael Figueiredo, com o tema: Inovação nas compras públicas.</p>
                                </section>
                            </div>
                        </section>
                    </section>

                    <Links />
                </div>
            </main>
            
            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default SegundoEvento