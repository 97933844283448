import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Sobre from './Pages/Sobre/Sobre';
import Eventos from './Pages/Eventos/Eventos';
import Contato from './Pages/Contato/Contato';
import Galeria from './Pages/Galeria/Galeria';
import GaleriaPrimeiroEvento from './Pages/GaleriaPrimeiroEvento/GaleriaPrimeiroEvento';
import PrimeiroEvento from './Pages/PrimeiroEvento/PrimeiroEvento';
import SegundoEvento from './Pages/SegundoEvento/SegundoEvento';


function App() {
    return (
        <div className="App">
            <header className="App-header">
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/Sobre' element={<Sobre />} />
                        <Route path='/Eventos' element={<Eventos />} />
                        <Route path='/Contato' element={<Contato />} />
                        <Route path='/Galeria' element={<Galeria />} />
                        <Route path='/GaleriaPrimeiroEvento' element={<GaleriaPrimeiroEvento />} />
                        <Route path='/PrimeiroEvento' element={<PrimeiroEvento />} />
                        <Route path='/SegundoEvento' element={<SegundoEvento />} />
                    </Routes>
                </BrowserRouter>
            </header>
        </div>
    );
}

export default App;
