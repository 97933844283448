import React from 'react'
import './Button.css'


const Button = (props) => {
    return (
        <div className='button__container'>
            <span>{props.nome}</span>
        </div>
    )
}

export default Button