import React from 'react'
import './ListasEvento.css'

const ListasEvento = (props, index) => {
    return (
        <div>
            {props.item.map(item => {
                return (
                    <div key={index} className="listasEvento__container">
                        <img src={item.image} className='listasEvento__img' alt="Eventos do Aprender e Capacitar" />
                        <span className='listasEvento__number'>{item.dateNumber}</span>

                        <div className="listasEvento__date">
                            <span>{item.dateDay}</span>
                            <span>{item.dateMonth}</span>
                        </div>

                        <p className='listasEvento__text'>{item.text}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default ListasEvento