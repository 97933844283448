import React from 'react'
import './GaleriaPrimeiroEvento.css'

import Navbar from '../../Components/Navbar/Navbar'
import Inside from '../../Components/Inside/Inside'

const GaleriaPrimeiroEvento = () => {

    const imagens = [
        './images/Primeiro Evento/P1.jpeg',
        './images/Primeiro Evento/P2.jpeg',
        './images/Primeiro Evento/P3.jpeg',
        './images/Primeiro Evento/P4.jpeg',
        './images/Primeiro Evento/P5.jpeg',
        './images/Primeiro Evento/P6.jpeg',
        './images/Primeiro Evento/P7.jpeg',
        './images/Primeiro Evento/P8.jpeg',
        './images/Primeiro Evento/P9.jpeg',
        './images/Primeiro Evento/P10.jpeg',
        './images/Primeiro Evento/P11.jpeg',
        './images/Primeiro Evento/P12.jpeg',
        './images/Primeiro Evento/P13.jpeg',
        './images/Primeiro Evento/P14.jpeg',
        './images/Primeiro Evento/P15.jpeg',
        './images/Primeiro Evento/P16.jpeg',
        './images/Primeiro Evento/P17.jpeg',
        './images/Primeiro Evento/P18.jpeg'
    ]

    return (
        <div className='galeriaEventos__container'>
            <header>
                <Navbar />
            </header>

            <main className='galeriaEventos__main'>
                <div className="main__texts--container">
                    <p>Qui. 25 a 28 de mai | Hotel Littoral</p>
                    <h1>Congresso Nacional de Agentes Públicos e Políticos</h1>
                </div>

                <div className="main__img--container">
                    {imagens.map((url, index) => {
                        return (
                            <div key={index} className='img__container--indi'>
                                <img src={url} className='main__img' alt="Momentos do Congresso" />
                            </div>
                        )
                    })}
                </div>
            </main>

            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default GaleriaPrimeiroEvento