import React from "react";
import './Links.css'

import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BiLogoFacebook } from "react-icons/bi";
import { AiFillYoutube } from "react-icons/ai";
import { Link } from "react-router-dom";

const Links = () => {
    return (
        <section className="links__container">
            <Link to="https://www.instagram.com/aprenderecapacitarbr/" target="_blank">
                <AiOutlineInstagram className="links__icon" />
            </Link>

            <Link to="https://wa.me/5581993354483" target="_blank">
                <AiOutlineWhatsApp className="links__icon" />
            </Link>

            <Link to="https://www.facebook.com/profile.php?id=100087142742395&mibextid=LQQJ4d" target="_blank">
                <BiLogoFacebook className="links__icon" />
            </Link>

            <Link to="https://www.youtube.com/@Aprenderecapacitarbr" target="_blank">
                <AiFillYoutube className="links__icon" />
            </Link>
        </section>
    )
}

export default Links