import React from 'react'
import './Events.css'

import Button from '../Button/Button'
import ListasEvento from '../ListasEvento/ListasEvento'

import { Link } from "react-router-dom";


const Events = (props) => {

    const infoEventoAtual = [
        {
            image: './images/events - pic2.png',
            dateNumber: '24',
            dateDay: 'Qui.',
            dateMonth: 'Ago.',
            text: 'III Seminário Nordestino'
        }

    ]

    const infoEventoPassado = [
        {
            image: './images/events - pic1.png',
            dateNumber: '25',
            dateDay: 'Qui.',
            dateMonth: 'Mai.',
            text: 'Congresso Nacional'
        }

    ]


    return (
        <section className='events__container'>
            <h2 className='events__h2'>{props.titulo}</h2>
            <h2 className='events__titulo'>Eventos</h2>

            <section key={infoEventoAtual} className='events__section--container'>
                <section className="events__eventoAtual--container">
                    <ListasEvento item={infoEventoAtual} />
                    <Link to="/SegundoEvento" className='button__evento'><Button nome="Saiba Mais"/></Link>
                    <Link to="/Galeria" className='button__galeria'><Button nome="Visualizar imagens"/></Link>
                </section>

                <section className="events__eventoPassado--container">
                    <ListasEvento item={infoEventoPassado} />
                    <Link to="/PrimeiroEvento" className='button__evento'><Button nome="Saiba Mais"/></Link>
                    <Link to="/GaleriaPrimeiroEvento" className='button__galeria'><Button nome="Visualizar imagens"/></Link>
                </section>
            </section>
        </section>
    )
}

export default Events