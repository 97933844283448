import React from 'react'
import './Contato.css'

import Inside from '../../Components/Inside/Inside'
import Links from '../../Components/Links/Links'
import Navbar from '../../Components/Navbar/Navbar'

import { Link } from 'react-router-dom'

const Contato = () => {

    const endereco = [
        {
            titulo: 'Endereço',
            end: 'Avenida Senador Salgado Filho, Paulista - Centro, PE',
            local: 'Loja 46',
            cep: 'CEP: 53401 - 440',
            img: './images/map.png'
        }
    ]

    return (
        <div className='contato__container'>
            <header>
                <Navbar />
            </header>

            <main>
                <div className="contato__align--container">
                    <h2 className='contato__h2'>Contato e Local</h2>
                    {endereco.map((item, index) => {
                        return (
                            <address key={index}>
                                <div className="address__text--container">
                                    <h3>{item.titulo}</h3>
                                    <p className='address__text--local'>{item.end}</p>
                                    <p>{item.local}</p>
                                    <p>{item.cep}</p>
                                </div>

                                <Link to={'https://www.google.com/maps/place/Av.+Sen.+Salgado+Filho,+21+-+Nobre,+Paulista+-+PE,+53401-440/@-7.9393192,-34.8800943,17z/data=!3m1!4b1!4m6!3m5!1s0x7ab16125e21f275:0x734f1cb3bfe85f70!8m2!3d-7.9393192!4d-34.8800943!16s%2Fg%2F11h_khr24_?entry=ttu'} className='link__contato--img' target='_blank'>
                                    <img src={item.img} className='contato__map' alt="Map" />
                                </Link>
                            </address>
                        )
                    })}
                    <Links />
                </div>
            </main>

            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default Contato