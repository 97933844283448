import React, { useState, useEffect } from "react";
import './Slider.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade } from 'swiper/modules';

const Slider = () => {
    const imagesDesktop = [
        { id: '1', image: './images/desktop - capa.png' },
        { id: '2', image: './images/desktop - palestrantes.png' }
    ]

    const imagesMobile = [
        { id: '1', image: './images/mobile - capa.png' },
        { id: '2', image: './images/mobile - palestrantes 1.png' },
        { id: '3', image: './images/mobile - palestrantes 2.png' },
        { id: '4', image: './images/mobile - palestrantes 3.png' },
        { id: '5', image: './images/mobile - palestrantes 4.png' },
    ]

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Função para verificar se o tamanho da tela é menor que 768 pixels (versão mobile)
        const handleResize = () => {
            setIsMobile(window.innerWidth < 840);
        };

        // Verificar o tamanho da tela no carregamento inicial
        handleResize();

        // Adicionar um ouvinte para verificar o tamanho da tela ao redimensionar a janela
        window.addEventListener('resize', handleResize);

        // Remover o ouvinte quando o componente for desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="slider">
            <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                speed={4000}
                autoplay={{
                    delay: 1500
                }}
                modules={[EffectFade]}
                effect="fade"
            >
                {/* Renderizar as imagens da versão desktop se não for mobile */}
                {!isMobile && imagesDesktop.map((item) => (
                    <SwiperSlide key={item.id}>
                        <img src={item.image} className="slider__img" alt="Carrossel" />
                    </SwiperSlide>
                ))}

                {/* Renderizar as imagens da versão mobile se for mobile */}
                {isMobile && imagesMobile.map((item) => (
                    <SwiperSlide key={item.id}>
                        <img src={item.image} className="slider__img" alt="Carrossel" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default Slider;